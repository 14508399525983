import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/news.less'
import Logo from '../../images/logo.svg'
import bajutina from '../../images/bajutina.jpg'
import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'

const TITLE =
  'Ипотека.Центр и Совкомбанк запускают акцию для ипотечных экспертов'

const SovcombankContest = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={TITLE} />
        <meta property="og:description" content={TITLE} />
      </Helmet>
      <ScrollToTopOnMount />
      <div className="news-layout">
        <header className="news-header">
          <a href="/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </header>
        <article className="news-article">
          <h1>{TITLE}</h1>
          <h3 className="news-article__lead">
            Совместно с Совкомбанком запускаем акцию для&nbsp;профессионалов
            рынка ипотеки и недвижимости{' '}
            <a
              href="https://events.ipoteka.center/sovcombank-contest"
              target="_blank"
            >
              «Выгодные сделки»
            </a>
            .
          </h3>
          <section className="news-text-section">
            <p className="news-paragraph">
              Для участия в акции необходимо в период с 15 февраля по 30 апреля
              2023 года провести через платформу Ипотека.Центр сделки с
              ипотечными продуктами Совкомбанка. Победители будут определены по
              итогам акции по наибольшему объему выданных кредитов в нескольких
              номинациях:
            </p>
            <p className="news-paragraph">
              <ol className="news-paragraph__list">
                <li>
                  Ипотечный эксперт Совкомбанка в Москве и&nbsp;Московской
                  области – 2 победителя
                </li>
                <li>
                  Ипотечный эксперт Совкомбанка в Санкт-Петербурге и
                  Ленинградской области – 2&nbsp;победителя
                </li>
                <li>
                  Региональный ипотечный эксперт Совкомбанка – 2 победителя.
                </li>
              </ol>
            </p>
            <p className="news-paragraph">
              Итоги будут объявлены 16 мая 2023 года на&nbsp;сайте акции
              и&nbsp;в&nbsp;информационных каналах Ипотека.Центр
              и&nbsp;Совкомбанка. Главные эксперты акции будут награждены
              техникой Apple – планшетами iPad и колонкой Mini. Участники,
              занявшие вторые места, получат бесплатное обучение в осеннем
              потоке Ипотечного университета от Ипотека.Центр. Все победители
              примут участие в интервью по&nbsp;итогам акции, а также получат
              приглашения на&nbsp;партнерские мероприятия Ипотека.Центр, которые
              состоятся в течение года в крупных городах России.
            </p>
          </section>
          <section className="news-citations">
            <div className="news-citations__item">
              <p>
                Совкомбанк стал одним из первых банков на платформе
                Ипотека.Центр и на протяжении долгого времени входит в число
                основных партнеров с выгодными ипотечными программами. Принимая
                участие в акции профессионалы рынка смогут не только получить
                подарки и оценить удобство работы на платформе Ипотека.Центр, но
                и повысить уровень вознаграждения до 5,2% при проведении
                ипотечной сделки с приобретением объектов недвижимости от
                ведущих застройщиков, представленных на платформе. Все статусы
                сделки можно контролировать онлайн в личном кабинете, а также
                получать поддержку профессионального куратора в сложных сделках.
                Кроме того, Ипотека.Центр предоставляет доступ к бесплатным
                обучающим материалам и возможность работы на платформе из любой
                точки мира
              </p>
              <div className="news-citations__author">
                <img src={bajutina} alt="Ольга Бажутина" />
                <div>
                  <h5>Ольга Бажутина</h5>
                  <span>генеральный директор Ипотека.Центр</span>
                </div>
              </div>
            </div>
          </section>
          <section className="news-text-section">
            <p className="news-paragraph">
              Совкомбанк предлагает максимальный размер комиссии на платформе до
              1,2%, конкурентные рыночные ставки от 3,9% годовых, а также опции
              по снижению процентов. Кроме того, удобство работы с банком
              заключается в одобрении крупных сумм кредитов до 200 миллионов
              рублей, высокой лояльности и работе с разными типами клиентов –
              ИП, собственниками бизнеса, самозанятыми и пенсионерами.
            </p>
            <p className="news-paragraph">
              Участвовать в акции могут только зарегистрированные пользователи
              Ипотека.Центр. Подробнее об акции, условиях проведения и подарках
              можно ознакомиться на сайте:{' '}
              <a
                href="https://events.ipoteka.center/sovcombank-contest"
                target="_blank"
              >
                https://events.ipoteka.center/sovcombank-contest
              </a>
              .
            </p>
          </section>
        </article>
        <footer className="news-footer">
          <h6 className="news-footer__title">Дисклеймер</h6>
          <p className="news-paragraph">
            Организатор конкурса — ООО «Финмаркет», юридический адрес: 123112,
            г. Москва, Пресненская наб., д. 6, стр. 2, эт. 5, пом. 1, ком. 25,
            ОГРН 1187746639783, ИНН 7703461382, КПП 77030100 (далее —
            Ипотека.Центр).
          </p>
          <p className="news-paragraph">
            Ипотека.Центр — ипотечная IT компания, находящаяся на стыке облачных
            технологий и&nbsp;реального бизнеса.
          </p>
          <p className="news-paragraph">
            Платформа Ипотека.Центр – собственная российская разработка ООО
            «ФинМаркет». Инновационный сервис для профессионалов ипотечного
            бизнеса, позволяет проводить полный цикл сделки от подбора объекта
            недвижимости в России и зарубежом до выдачи ипотечного кредита,
            контролировать все процессы онлайн и получать высокое агентское
            вознаграждение. Платформа была запущена в июне 2019 года. В июне
            2020 года платформа стала первым ипотечным резидентом Сколково.
            Представительства Ипотека.Центр работают в четырех городах: Москва,
            Санкт-Петербург, Новосибирск, Воронеж и Краснодар.
          </p>
          <p className="news-paragraph">
            Подробная информация об организаторе Конкурса, о правилах его
            проведения, количестве Призов по результатам Конкурса,сроках, месте
            и порядке их получения опубликованы на сайте{' '}
            <a href="https://partner.ipoteka.center/contest" target="_blank">
              https://partner.ipoteka.center/contest
            </a>
            . Не является публичной офертой. Реклама. ООО «Финмаркет».
          </p>
          <p className="news-paragraph">
            Контакты для прессы:{' '}
            <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
          </p>
        </footer>
      </div>
    </>
  )
}

export default SovcombankContest
